import { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router";
import { SecureRoute, LoginCallback } from "@okta/okta-react";
import { EuiProgress } from "@elastic/eui";
import { store } from "./redux/store";
import "./components/css/colors.css";
const LoginContainer = lazy(() => import("./pages/Login/Login"));
const AdminContainer = lazy(() => import("./containers/AdminContainer"));
const AuthenticatedContainer = lazy(() => import("./containers/AuthenticatedContainer"));
const EstablishPasswordContainer = lazy(() => import("./containers/EstablishPasswordContainer"));
const Logout = lazy(() => import("./components/Logout"));
const NotFound = lazy(() => import("./components/NotFound"));
const Unauthorized = lazy(() => import("./pages/Unauthorized"));
const UnavailableContainer = lazy(() => import("./components/Unavailable"));

import { HOME_PAGE } from "Consts";

const isLoggedInRequired = (location) => {
  const state = store.getState();
  const { auth }: any = state;
  const { currentUser } = auth;
  if (!currentUser || !currentUser.userID) {
    return <Redirect to={{ pathname: "/login", state: { redirectUrl: location.pathname } }} />;
  } else return null;
};

// const isOnboardingRequired = (location) => {
// commented when asked not to force the onboarding popup
//   if (location?.pathname?.toLowerCase() == ONBOARDING_PAGE) {
//     // already redirecting
//     return null;
//   }
//   const state = store.getState();
//   const { globalFilter } = state;
//   const { clientInfo } = globalFilter;

//   const isOnboardingAuthorizedForUser = isSubjectAuthorizedForUser({
//     subject: Subjects.Client_Onboarding,
//     role: Roles.Write,
//   });

//   const isOnboardingFortifiedAdmin = isSubjectAuthorizedForUser({
//     subject: Subjects.Client_Onboarding,
//     role: Roles.Admin,
//   });

//   if (
//     clientInfo &&
//     !clientInfo.isOnboardingComplete &&
//     isOnboardingAuthorizedForUser &&
//     !isOnboardingFortifiedAdmin
//   ) {
//     return (
//       <Redirect
//         to={{
//           pathname: ONBOARDING_PAGE,
//         }}
//       />
//     );
//   }
//   return null;
// };

const Routes = (props) => {
  return (
    <Suspense fallback={<EuiProgress color="accent" size="xs" />}>
      <Switch>
        <Route component={LoginCallback} path="/implicit/callback" />
        <Route exact path="/login" render={(props) => <LoginContainer {...props} />} />
        <Route component={EstablishPasswordContainer} path="/confirmations/:confirmationToken" />
        <Route component={EstablishPasswordContainer} path="/forgot_password/:passwordResetToken" />

        <SecureRoute
          exact
          path="/"
          render={(props) => {
            return isLoggedInRequired(props.location) ?? <Redirect to={HOME_PAGE} />;
          }}
        />

        <SecureRoute
          path={[
            "/home",
            "/home_v2",
            "/ask_wisdom",
            "/monitoring",
            "/environment",
            "/inventory",
            "/database",
            "/compliance",
            "/account",
            "/assessment",
          ]}
          render={(props) => {
            const loginRedirect = isLoggedInRequired(props.location);

            if (loginRedirect) {
              return loginRedirect;
            }
            return <AuthenticatedContainer />;
          }}
        />

        <SecureRoute
          path="/admin"
          render={(props) => {
            const loginRedirect = isLoggedInRequired(props.location);

            if (loginRedirect) {
              return loginRedirect;
            }

            const state = store.getState();
            const { auth }: any = state;
            const { currentUser } = auth;

            if (/^Portal/.test(currentUser?.roleName ?? "")) {
              return (
                <Redirect
                  to={{
                    pathname: "/forbidden",
                    state: { from: props.location },
                  }}
                />
              );
            }
            return <AdminContainer />;
          }}
        />
        <Route
          exact
          path="/reset_password"
          render={(props) => <LoginContainer resetPassword={1} {...props} />}
        />

        <Route component={Unauthorized} path="/forbidden" />
        <Route component={UnavailableContainer} path="/unavailable" />
        <Route
          path="/logout"
          render={(props) => {
            //debugger;
            const logoutProps = {
              Message: props?.location?.state?.msg,
              from: window.location.href,
              origin: "route.js",
            };
            return <Logout {...logoutProps} />;
          }}
        />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
